<template>
    <div class="wrap">
        <div :id="imgId" class="imgGrop">
            <div class="left middleCenter" v-for="(item, index) in fileLists" :id="index + 1" :key="index + 1"
                :data-id="item.url">
                <img class="img" :src="item.url">
                <div class="content-wrap">
                    <div class="content middleCenter">
                        <i class="el-icon-zoom-in ci" @click="handlePictureCardPreview(item)"></i>
                        <i class="el-icon-delete ci" @click="handleRemove(item)" v-if="delremove"></i>
                    </div>
                </div>
            </div>
        </div>
        <el-upload :action="actionUrl" list-type="picture-card" accept=".jpg,.jpeg,.png" :on-success="handleSuccess"
            :file-list="fileLists" :on-change="handleChange" :show-file-list="false" :class="{ hide: hideUploadEdit }">
            <i slot="default" class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
import ty from "../../utils/ty";
import Sortable from 'sortablejs';
export default {
    name: "VploadImgTwo",
    props: {
        limit: {
            type: Number,
            default: 12,
        },
        action: {
            type: String,
            default: ty.apiurl + '/common/uploadFileUrl'
        },
        type: {
            type: Number,
            default: 0
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        delremove: {
            type: [Boolean, String],
            default: true
        },
        fileList: {
            type: Array,
            default: () => {
                return []
            }
        },
        isSort: {
            type: Boolean,
            default: false
        },
        imgId: {
            type: String,
            default: "imgGrop"
        }
    },

    data() {
        return {
            dialogImageUrl: '',
            dialogVisible: false,
            actionUrl: this.action + '?fileType=0&type=' + this.type,
            hideUploadEdit: this.fileList.length >= this.limit,
            fileLists: this.fileList,
            editForm: {
                url: '',
                uid: null
            },
            editView: false,

        }
    },
    mounted() {
        var el = document.getElementById(this.imgId);
        const that = this;
        var sortable = Sortable.create(el, {
            sort: that.isSort,
            // 结束拖拽
            onEnd: function (obj) {
                var imgList = sortable.toArray()
                that.submitFile(imgList, 2)
            },
        });
    },
    watch: {
        fileList(n, o) { //n为新值,o为旧值;
            this.fileLists = n;
            this.hideUploadEdit = this.fileList.length >= this.limit
        }
    },
    methods: {
        // 移除图片
        handleRemove(file) {
            this.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                for (let i in this.fileLists) {
                    if (this.fileLists[i].uid == file.uid) {
                        this.fileLists.splice(i, 1)
                    }
                }
                this.hideUploadEdit = this.fileLists.length >= 1;
                this.submitFile(file.url, 0)
            }).catch(() => {
            });
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleChange(file, fileList) {
            this.hideUploadEdit = fileList.length >= 1;
        },
        handleSuccess(response, file, fileList) {
            let obj = {
                name: file.name,
                status: "success",
                uid: file.uid,
                url: response.msg
            }
            this.uploadProgress = 100
            this.preventSumit = false

            this.fileLists.push(obj)
            this.submitFile(response.msg, 1)
        },
        // 将图片文件传回给父组件
        submitFile(filePath, add) {
            //add:0是删除。1是新增,2是重新排序
            this.$emit('submitImg', filePath, add)
        },
    }
}
</script>

<style scoped>
.el-icon-plus {
    font-size: 30px !important;
}

.el-input>>>.el-textarea__inner {
    font-size: 18px !important;
}

.left {
    float: left;
    width: 148px;
    height: 148px;
    border-radius: 6px;
    border: 1px solid #c0ccda;
    margin: 0 20px 20px 0;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.content-wrap {
    display: none;
    position: absolute;
    z-index: 99999999;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    padding-top: 70px;
}

.left:hover .content-wrap {
    display: block;
}

.content {
    width: 100%;
    height: 100%;
    text-align: center;
}

.ci {
    color: #fff;
    font-size: 18px;
}

.ci:nth-of-type(2) {
    margin-left: 10px;
}

.ci:nth-of-type(1) {
    margin-right: 10px;
}

.wrap {
    float: left;
}

.imgGrop {
    float: left;
}

.hide>>>.el-upload--picture-card {
    display: none;
}</style>
